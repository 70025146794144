export class BranchDetailsFormControls {
  public static uid = 'uid';
  public static branchName = 'name';
  public static emails = 'emails';
  public static phones = 'phones';
  public static online = 'online';
  public static timeslotId = 'timeslotId';
  public static deliveryOptions = 'deliveryOptions';
  public static deliverytypeid = 'deliverytypeid';
  public static branchdeliveryareasId = 'branchdeliveryareasId';
  public static freeShipping = 'freeShipping';
  public static selectedproducts = 'selectedproducts';
  public static localPickup = 'localPickup';
  public static formType={
    onDemandForm:'onDemandForm',
    ondemand:'ondemand',
    bundled : 'bundled',
    branch_Added:'branch_Added',
  }
  public static forTableQROnly = 'forTableQROnly';
  public static response_Code={
    category_Added:'category_Added',
    category_Exist:'category_Exist',
    category_Updated:'category_Updated',
    category_Deleted: 'category_Deleted',
    category_Associated:'category_Associated',
    product_Upadated :'product_Upadated',
    product_Exist:'product_Exist',
    product_Added:'product_Added',
    product_Deleted: 'product_Deleted',
    branch_Added: 'branch_Added',
    branch_Exist:'branch_Exist'
  }
}

export class OrderDetailsFormControl {
  public static address = "address";
  public static street = "street";
  public static streetno = "streetno";
  public static city = "city";
  public static zipcode = "zipcode";
  public static settingStreet = "settingStreet";
  public static settingCity = "settingCity";
  public static settingZip = "settingZip";
  public static deliverydate = 'deliverydate';
  public static deliverytime = 'deliverytime';
  public static invoiceAddress = 'invoiceAddress';
  public static email = 'email';
  public static phone = 'phone';
  public static status  = 'status';
  public static note  = 'note';
  public static memberId = 'memberId';
  public static branchId = 'branchId';
  public static price  = 'price';
  public static payment = 'payment';
  public static rowcreated = 'rowcreated';
  public static orderplacedtime = 'orderplacedtime';
  public static type = 'type';
  public static deliverytype = 'deliverytype';
  public static memberaddress = 'memberaddress';
  public static memberplz = 'memberplz';
  public static membercity = 'membercity';
  public static memberstreetno = 'memberstreetno';
  public static memberstreet = 'memberstreet';
  public static firstName = 'firstName';
  public static lastName = 'lastName';
  public static memberfirstname = 'memberfirstname';
  public static memberlastname = 'memberlastname';

  public static mFirstName = 'mFirstName';
  public static mLastName = 'mLastName';
  public static mStreet = 'mStreet';
  public static mStreetNumber = 'mStreetNumber';

  public static landmark = 'landmark';
  public static tablenumber = 'tablenumber';
  public static firmName='firmName';
  


  
}
export class QRDailogFormControl {
  public static Message = "address";
  public static Title = "street";
}
